export default {
	ruby: `require "uri"
require "net/http"

url = URI("https://api.mista.io/sms")

https = Net::HTTP.new(url.host, url.port);
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["x-api-key"] = "XXXXXXXX"
form_data = [['action', 'check-balance'],['response', 'json']]
request.set_form form_data, 'multipart/form-data'
response = https.request(request)
puts response.read_body`,

	python: `import requests

url = "https://api.mista.io/sms"

payload = {'action': 'check-balance',
'response': 'json'}
files = [

]
headers = {
  'x-api-key': 'XXXXXXXX'
}

response = requests.request("POST", url, headers=headers, data = payload, files = files)

print(response.text.encode('utf8'))`,

	php: `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "https://api.mista.io/sms",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => array('action' => 'check-balance','response' => 'json'),
  CURLOPT_HTTPHEADER => array(
    "x-api-key: XXXXXXXX"
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`,

	javascript: `var form = new FormData();
form.append("action", "check-balance");
form.append("response", "json");

var settings = {
  "url": "https://mistasms.com/sms",
  "method": "POST",
  "timeout": 0,
  "headers": {
    "x-api-key": "XXXXXXXX"
  },
  "processData": false,
  "mimeType": "multipart/form-data",
  "contentType": false,
  "data": form
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

	java: `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("text/plain");
RequestBody body = new MultipartBody.Builder().setType(MultipartBody.FORM)
  .addFormDataPart("action", "check-balance")
  .addFormDataPart("response", "json")
  .build();
Request request = new Request.Builder()
  .url("https://mistasms.com/sms")
  .method("POST", body)
  .addHeader("x-api-key", "XXXXXXXX")
  .build();
Response response = client.newCall(request).execute();`,

	curl: `curl --location --request POST 'https://api.mista.io/sms' \
--header 'x-api-key: XXXXXXXX' \
--form 'action=check-balance' \
--form 'response=json'`,

	http: `POST /sms HTTP/1.1
Host: api.mista.io
x-api-key: XXXXXXXXContent-Type: multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW

----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="action"

check-balance
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="response"

json
----WebKitFormBoundary7MA4YWxkTrZu0gW`,

	response: `{
    "balance": "Your Balance",
    "user": "Your Name",
    "country": "Your Country"
}`,
};

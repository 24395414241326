import React from 'react';
import { Row, Col, Table, Badge, Form, Image } from 'react-bootstrap';
import { CodeBlock, CopyBlock, ocean } from 'react-code-blocks';
import { AppLayout } from '../../layouts/app';
import UssdSampleCode from '../../components/code/UssdSampleCode';
import img from '../../assets/img/ussd/mista_ussd_flow.svg';

export class SampleCodes extends React.Component {
constructor(props) {
		super(props);

		this.state = {
			laungage: 'php',
			code: UssdSampleCode['php'],
		lang: ['php'],
		};

}

handleLaungage(e) {
		this.setState({
			laungage: e.target.value,
			code: UssdSampleCode[e.target.value],
		});
	}


render() {
		return (
			<AppLayout sidebar='ussd'>
				<Row>
					<Col lg={12} xs={12}>
						<Row>
							<Col lg={12} xs={12}>
								<h2>Sample USSD App</h2>
								<span class='line mt-3 mb-4'></span>
								<p>with this piece of code you can build a simple ussd application than let user check the balance of Cleaning Bill balance </p>
							</Col>
						</Row>

						

						<Row className='mt-12'>
							<Col lg={12} xs={12}>
								<Form.Group>
									<Form.Control
										as='select'
										custom
										onChange={e => this.handleLaungage(e)}
									>
										{this.state.lang.map(lang => (
											<option key={lang} value={lang}>
												{lang}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>

						<Row className='code-block'>
							<Col lg={12} xsxs={12}>
								<CopyBlock
									language={this.state.laungage}
									text={this.state.code}
									theme={ocean}
									showLineNumbers={true}
									codeBlock
								/>
							</Col>
						</Row>

					
					</Col>

				</Row>
			</AppLayout>
		);
	}
}

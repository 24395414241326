import React from 'react';
import { Row, Col, Table, Badge, Form, Image } from 'react-bootstrap';
import { CodeBlock, CopyBlock, ocean } from 'react-code-blocks';

import { AppLayout } from '../../layouts/app';

import ScheduleSms from '../../components/code/ScheduleSms';
import img from '../../assets/img/sms/schedule sms.png';

export class ScheduleSMSPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			laungage: 'ruby',
			code: ScheduleSms['ruby'],
			lang: ['ruby', 'python', 'php', 'javascript', 'java', 'curl', 'http'],
		};
	}

	handleLaungage(e) {
		this.setState({
			laungage: e.target.value,
			code: ScheduleSms[e.target.value],
		});
	}

	render() {
		return (
			<AppLayout sidebar='sms'>
				<Row>
					<Col lg={6} xs={12}>
						<Row>
							<Col lg={12} xs={12}>
								<h2>Send Schedule SMS</h2>
								<span class='line mt-3 mb-4'></span>
								<p>Schedule sms be sent at given time</p>
							</Col>
						</Row>

						<Row className='mt-3'>
							<Col lg={12} xs={12}>
								<Table>
									<tbody>
										<tr>
											<td>https://api.mista.io/sms</td>
											<td className='float-right'>
												<h6>
													<Badge variant='success'>POST</Badge>
												</h6>
											</td>
										</tr>
									</tbody>
								</Table>

								<p className='mt-5'>Headers</p>
								<Table>
									<tbody>
										<tr>
											<td className='bold'>x-api-key</td>
											<td>xxxx-xxxx-xxxx</td>
										</tr>
									</tbody>
								</Table>

								<p className='mt-5'>Body</p>
								<Table>
									<tbody>
										<tr>
											<td className='bold'>action</td>
											<td>send-sms</td>
										</tr>
										<tr>
											<td className='bold'>to</td>
											<td>Phone number</td>
										</tr>
										<tr>
											<td className='bold'>from</td>
											<td>Sender ID</td>
										</tr>
										<tr>
											<td className='bold'>sms</td>
											<td>Message</td>
										</tr>
										<tr>
											<td className='bold'>schedule</td>
											<td>date and time</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>

						<Row className='mt-5'>
							<Col lg={4} xs={12}>
								<Form.Group>
									<Form.Control
										as='select'
										custom
										onChange={e => this.handleLaungage(e)}
									>
										{this.state.lang.map(lang => (
											<option key={lang} value={lang}>
												{lang}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>

						<Row className='code-block'>
							<Col lg={12} xsxs={12}>
								<CopyBlock
									language={this.state.laungage}
									text={this.state.code}
									theme={ocean}
									showLineNumbers={true}
									codeBlock
								/>
							</Col>
						</Row>

						<Row>
							<p className='mt-5 col-md-12'>Response</p>

							<Col lg={12} xs={12}>
								<CodeBlock
									language='typescript'
									text={ScheduleSms['response']}
									theme={ocean}
									showLineNumbers={false}
								/>
							</Col>
						</Row>
					</Col>

					<Col lg={6} xs={12} className='rightbar'>
						<Image
							src={img}
							title='Send Schedule SMS'
							alt='Send Schedule SMS'
							className='img-fluid'
						/>
					</Col>
				</Row>
			</AppLayout>
		);
	}
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

export class UssdSidebar extends React.Component {
	render() {
		const { sidebarHidden } = this.props;

		return (
			<Col lg={2} className={sidebarHidden ? 'sidebar' : 'sidebar-show'}>
				<div className='mt-5 mb-5'>
					<p class='ml-2'>USSD Api Requests</p>

					<div className='menu mt-2'>
						<li class='nav-item'>
							<Link to='/ussd' className='nav-link' onClick={this.hideSideBar}>
								How it works
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/ussd/handle-sessions'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Handle USSD Sessions
							</Link>
						</li>

						<li class='nav-item'>	
						<Link to={{ pathname: "https://simulator.mista.io" }} 
                         className='nav-link'
						target="_blank" >
						Simulator
						</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/ussd/sample-codes'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Sample Codes
							</Link>
						</li>
					</div>
				</div>
			</Col>
		);
	}
}

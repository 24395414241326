export default {
	ruby: `require "uri"
require "net/http"

url = URI("https//api.mista.io/sms")

https = Net::HTTP.new(url.host, url.port);
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["x-api-key"] = "{{api_key}}"
form_data = [['action', 'subscribe-us'],['phone_book', 'phonebook name'],['phone_number', 'contact  first name'],['first_name', 'contact  first name'],['last_name', 'contact last name'],['email', 'contact email'],['company', 'contact company name '],['user_name', 'username']]
request.set_form form_data, 'multipart/form-data'
response = https.request(request)
puts response.read_body`,

	python: `import requests

url = "https//api.mista.io/sms"

payload = {'action': 'subscribe-us',
'phone_book': 'phonebook name',
'phone_number': 'contact  first name',
'first_name': 'contact  first name',
'last_name': 'contact last name',
'email': 'contact email',
'company': 'contact company name ',
'user_name': 'username'}
files = [

]
headers = {
  'x-api-key': '{{api_key}}'
}

response = requests.request("POST", url, headers=headers, data = payload, files = files)

print(response.text.encode('utf8'))`,

	php: `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "https//api.mista.io/sms",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => array('action' => 'subscribe-us','phone_book' => 'phonebook name','phone_number' => 'contact  first name','first_name' => 'contact  first name','last_name' => 'contact last name','email' => 'contact email','company' => 'contact company name ','user_name' => 'username'),
  CURLOPT_HTTPHEADER => array(
    "x-api-key: {{api_key}}"
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`,

	javascript: `var form = new FormData();
form.append("action", "subscribe-us");
form.append("phone_book", "phonebook name");
form.append("phone_number", "contact  first name");
form.append("first_name", "contact  first name");
form.append("last_name", "contact last name");
form.append("email", "contact email");
form.append("company", "contact company name ");
form.append("user_name", "username");

var settings = {
  "url": "https://api.mista.io/sms",
  "method": "POST",
  "timeout": 0,
  "headers": {
    "x-api-key": "{{api_key}}"
  },
  "processData": false,
  "mimeType": "multipart/form-data",
  "contentType": false,
  "data": form
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

	java: `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("text/plain");
RequestBody body = new MultipartBody.Builder().setType(MultipartBody.FORM)
  .addFormDataPart("action", "subscribe-us")
  .addFormDataPart("phone_book", "phonebook name")
  .addFormDataPart("phone_number", "contact  first name")
  .addFormDataPart("first_name", "contact  first name")
  .addFormDataPart("last_name", "contact last name")
  .addFormDataPart("email", "contact email")
  .addFormDataPart("company", "contact company name ")
  .addFormDataPart("user_name", "username")
  .build();
Request request = new Request.Builder()
  .url("https://api.mista.io/sms")
  .method("POST", body)
  .addHeader("x-api-key", "{{api_key}}")
  .build();
Response response = client.newCall(request).execute();`,

	curl: `curl --location --request POST 'https://api.mista.io/sms' \
--header 'x-api-key: {{api_key}}' \
--form 'action=subscribe-us' \
--form 'phone_book=phonebook name' \
--form 'phone_number=contact  first name' \
--form 'first_name=contact  first name' \
--form 'last_name=contact last name' \
--form 'email=contact email' \
--form 'company=contact company name ' \
--form 'user_name=username'`,

	http: `POST /sms HTTP/1.1
Host: api.mista.io
x-api-key: {{api_key}}Content-Type: multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW

----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="action"

subscribe-us
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="phone_book"

phonebook name
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="phone_number"

contact  first name
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="first_name"

contact  first name
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="last_name"

contact last name
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="email"

contact email
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="company"

contact company name 
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="user_name"

username
----WebKitFormBoundary7MA4YWxkTrZu0gW`,

	response: `{
    "code": "ok",
    "message": "Subscription successfully done"
}`,
};

import React from 'react';
import { Row, Col, Table, Badge, Form, Image } from 'react-bootstrap';
import { CopyBlock, ocean } from 'react-code-blocks';

import { AppLayout } from '../../layouts/app';

import DeliveryReportCode from '../../components/code/DeliveryReportCode';
import img from '../../assets/img/sms/delivery report.png';

export class DeliveryReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			laungage: 'php',
			code: DeliveryReportCode['php'],
			lang: ['php'],
		};
	}

	handleLaungage(e) {
		this.setState({
			laungage: e.target.value,
			code: DeliveryReportCode[e.target.value],
		});
	}

	render() {
		return (
			<AppLayout sidebar='sms'>
				<Row>
					<Col lg={6} xs={12}>
						<Row>
							<Col lg={12} xs={12}>
								<h2>Delivery Report</h2>
								<span class='line mt-3 mb-4'></span>
								<p>Message Delivery Report Status</p>
							</Col>
						</Row>

						<Row className='mt-3'>
							<Col lg={12} xs={12}>
								<Table>
									<tbody>
										<tr>
											<td>https://api.mista.io/sms</td>
											<td className='float-right'>
												<h6>
													<Badge variant='success'>POST</Badge>
												</h6>
											</td>
										</tr>
									</tbody>
								</Table>

								<p className='mt-5'>Headers</p>
								<Table>
									<tbody>
										<tr>
											<td className='bold'>x-api-key</td>
											<td>xxxx-xxxx-xxxx</td>
										</tr>
									</tbody>
								</Table>

								<p className='mt-5'>Body</p>
								<Table>
									<thead>
										<tr>
											<th>Field</th>
											<th>Type</th>
											<th>Description</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>status</td>
											<td>string</td>
											<td>Message status from Mists sms gateway</td>
										</tr>
										<tr>
											<td>messageId</td>
											<td>string</td>
											<td>Message id Captured from Api response</td>
										</tr>
									</tbody>
								</Table>

								<p className='mt-5'>Note</p>
								<Table>
									<tbody>
										<tr>
											<td>
												Message status may update several times, make sure to
												remain with messageid
											</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>

						<Row className='mt-5'>
							<Col lg={4} xs={12}>
								<Form.Group>
									<Form.Control
										as='select'
										custom
										onChange={e => this.handleLaungage(e)}
									>
										{this.state.lang.map(lang => (
											<option key={lang} value={lang}>
												{lang}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>

						<Row className='code-block'>
							<Col lg={12} xsxs={12}>
								<CopyBlock
									language={this.state.laungage}
									text={this.state.code}
									theme={ocean}
									showLineNumbers={true}
									codeBlock
								/>
							</Col>
						</Row>
					</Col>

					<Col lg={6} xs={12} className='rightbar'>
						<Image
							src={img}
							title='delivery status'
							alt='delivery status'
							className='img-fluid'
						/>
					</Col>
				</Row>
			</AppLayout>
		);
	}
}

import React from 'react';
import { Link } from 'react-router-dom';
import {
	Navbar,
	Image,
	Container,
	Row,
	Col,
	Form,
	Button,
	Nav,
} from 'react-bootstrap';

import { SmsSidebar } from '../components/sidebar/SmsSidebar';
import { UssdSidebar } from '../components/sidebar/UssdSidebar';
import MistaLogo from '../assets/img/mistalogo.svg';

export class AppLayout extends React.Component {
	constructor(props) {
		super(props);

		this.toggleSideBar = this.toggleSideBar.bind(this);
		this.hideSideBar = this.hideSideBar.bind(this);

		this.state = {
			sidebarHidden: true,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	toggleSideBar() {
		const currentState = this.state.sidebarHidden;
		this.setState({ sidebarHidden: !currentState });
	}

	hideSideBar() {
		this.setState({ sidebarHidden: true });
	}

	render() {
		const { sidebar } = this.props;

		return (
			<div>
				<Navbar
					collapseOnSelect
					sticky='top'
					expand='lg'
					variant='light'
					bg='white'
				>
					<Navbar.Brand>
						<Link to='/' onClick={this.hideSideBar}>
							<Image
								alt='Mista SMS'
								title='Mista SMS'
								src={MistaLogo}
								width='90%'
								className='d-inline-block align-top ml-2'
							/>
						</Link>
					</Navbar.Brand>

					<Navbar.Toggle onClick={this.toggleSideBar} />

					<Nav className='ml-auto'></Nav>

					<Form inline>
						<Button href='https://dashboard.mista.io' variant='outline-success'>
							SIGN IN
						</Button>
					</Form>
				</Navbar>

				<Container fluid className='body'>
					<Row>
						{sidebar === 'sms' ? (
							<SmsSidebar sidebarHidden={this.state.sidebarHidden} />
						) : (
							''
						)}

						{sidebar === 'ussd' ? (
							<UssdSidebar sidebarHidden={this.state.sidebarHidden} />
						) : (
							''
						)}

						<Col lg={10} xs={12} className='body-content'>
							<Container className='mt-5 mb-5'>{this.props.children}</Container>
						</Col>
					</Row>
				</Container>

				<footer class='p-4'>
					<div class='container'>
						<div class='row justify-content-center'>
							<div class='col-md-12 text-center'>
								<span>© 2021 Mista. All rights reserved</span>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';

import { AppLayout } from '../../layouts/app';

import img from '../../assets/img/ussd/mista_ussd_flow.svg';

export class HomeUSSDPage extends React.Component {
	render() {
		return (
			<AppLayout sidebar='ussd'>
				<Row>
					<Col lg={6} xs={12}>
						<Row>
							
								<h2>How it works</h2>
								<span class='line mt-3 mb-4'></span>

								<p>Build dynamic, scalable USSD and take advantage of handled complexity </p>

<p>Start processing USSD requests in three simple steps</p>

<p>egister a short code from Mista dashboard</p>
<ul>
<li>Add a callback URL that we will call whenever we get a request from a client coming into our system</li>
<li>Perform test on Mista USSD Simulator </li>
<li>Switch Short code to Live and users will start using their mobile phones to interact with your Application</li>
</ul>
<p>Once you register your callback URL, any requests we receive belonging to your service code will trigger a <code>HTTP POST</code> request to the registered callback with the requests data. You can read the data from the form fields of the request. Content-Type: <code>application/x-www-form-urlencoded</code>.</p>
<p>All you have to do at this point is respond with the string(Text) that you would like us to send back to the user.</p>

                        

							
						</Row>

						
					</Col>

					<Col lg={6} xs={8} className='rightbar-ussd'>
						
                            <Image
							src={img}
							title='Introduction Documentation'
							alt='Introduction Documentation'
							className='img-fluid'
						/>
					</Col>
				</Row>
			</AppLayout>
		);
	}
}

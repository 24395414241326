import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';

import { AppLayout } from '../../layouts/app';

import img from '../../assets/img/sms/documentation.png';

export class HomePage extends React.Component {
	render() {
		return (
			<AppLayout sidebar='sms'>
				<Row>
					<Col lg={6} xs={12}>
						<Row>
							<Col lg={12} xs={12}>
								<h2>Overview</h2>
								<span class='line mt-3 mb-4'></span>
								<p>
									SMS Notification are used to send regular updates and
									notification to the users. Mr SMS SMS API allows you to
									quickly create and configure a SMS Notifications for your
									business. An API call will trigger the message action and will
									enable sending SMS messages to the customers.
								</p>
							</Col>
						</Row>

						<Row className='mt-5'>
							<Col lg={12} xs={12}>
								<h2>Response codes</h2>
								<span class='line mt-3 mb-4'></span>
								<Table className='border'>
									<thead>
										<tr>
											<th>Status</th>
											<th>Response</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>OK</td>
											<td>Successfully Send</td>
										</tr>
										<tr>
											<td>100</td>
											<td>Bad gateway requested</td>
										</tr>
										<tr>
											<td>101</td>
											<td>Wrong action</td>
										</tr>
										<tr>
											<td>102</td>
											<td>Authentication failed</td>
										</tr>
										<tr>
											<td>103</td>
											<td>Invalid phone number</td>
										</tr>
										<tr>
											<td>104</td>
											<td>Phone coverage not active</td>
										</tr>
										<tr>
											<td>105</td>
											<td>Insufficient balance</td>
										</tr>
										<tr>
											<td>106</td>
											<td>Invalid Sender ID</td>
										</tr>
										<tr>
											<td>107</td>
											<td>Invalid SMS Type</td>
										</tr>
										<tr>
											<td>108</td>
											<td>SMS Gateway not active</td>
										</tr>
										<tr>
											<td>109</td>
											<td>Invalid Schedule Time</td>
										</tr>
										<tr>
											<td>110</td>
											<td>Media url required</td>
										</tr>
										<tr>
											<td>111</td>
											<td>SMS contain spam word. Wait for approval</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
					</Col>

					<Col lg={6} xs={12} className='rightbar'>
						<Image
							src={img}
							title='Introduction Documentation'
							alt='Introduction Documentation'
							className='img-fluid'
						/>
					</Col>
				</Row>
			</AppLayout>
		);
	}
}

import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';

import { AppLayout } from '../../layouts/app';

import img from '../../assets/img/ussd/ussd-icon.svg';

export class HandleSessions extends React.Component {
	render() {
		return (
			<AppLayout sidebar='ussd'>
				<Row>
					<Col lg={6} xs={12}>
						<Row>
							<Col lg={12} xs={12}>
								<h2>Handling a session</h2>
								<span class='line mt-3 mb-4'></span>
								
<p>Handling a ussd session was simplified as much as possible,you will need as to write a script  and host it on your web server the script will handles  <code>HTTP POST</code> requests.</p>
<p>Important things to know about USSD:</p>
<ul>
<li>USSD is session driven. Every request we send you will contain a sessionId, and this will be maintained until that session is completed</li>
<li>Every response much contain a variable  <code>ContinueSession</code> When value  <code>1</code>is assigned to it, you are telling network operator to continue the session ,while Value <code>0</code> means end of the session.</li>
<li>If user cancel or leave ussd menu prematurely , Mista Gateway will timeout the session after 2 minutes .</li>
<li>If we get a HTTP error response or a malformed JSON Response from your script , the session will also be terminated </li>
</ul>

							</Col>
						</Row>

						<Row className='mt-5'>
							<Col lg={12} xs={12}>
								<h2>API parameters</h2>
								
<p>The API makes a <code>HTTP POST</code> request to your server with the parameters shown below, you can read the data sent from the form fields of the request. Content-Type: <code>application/x-www-form-urlencoded</code>. This request is made when the user dials a USSD code and every time they respond to a menu.</p>

								<span class='line mt-3 mb-4'></span>
								<Table className='border'>
									<thead>
										<tr>
											<th>Parameter </th>
											<th>Format </th>											
											<th>Description</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>sessionId</td>
											<td>String</td>
											<td>The USSD sessionId as passed by the Telecom.
</td>
										</tr>
										<tr>
											<td>msisdn</td>
											<td>String</td>
											<td>The number of the mobile subscriber interacting with your ussd application.
</td>
										</tr>
										<tr>
											<td>UserInput</td>
											<td>String</td>
											<td>This shows the user input. It is an empty string in the first notification of a
session. After that, it concatenates all the user input within the session with a *
until the session ends.
</td>
										</tr>
											<tr>
										<td>serviceCode</td>
										<td>String</td>
										<td>This is the USSD short code assigned to your application.
										</td>
										</tr>
										<tr>
										<td>networkCode</td>
										<td>String</td>
										<td>TThe Mobile Network Operator identifier of the mobile subscriber interacting with
your ussd application
										</td>
										</tr>
										
									</tbody>
								</Table>
								  <h2>Expected APP/Service Response</h2>
								  <p><code>Your Application or Service is expected to respond with the following JSON payload</code></p>
								  		<Table className='border'>
									<thead>
										<tr>
											<th>Parameter </th>
											<th>Format </th>											
											<th>Description</th>
										</tr>
									</thead>
									<tbody>
										<tr>
										<td>sessionId</td>
										<td>String</td>
										<td>The USSD sessionId as passed in the previous USSD-Gateway
										call
										.
										</td>
										</tr>
										<tr>
										<td>message</td>
										<td>String</td>
										<td>The text or message to be passed back to the telco formatted with '\n' for
										new lines.
										</td>
										</tr>
									
										<tr>
											<td>ContinueSession</td>
											<td>Integer</td>
											<td><p>1- Continue with the USSD session i.e allow the mobile user
				to continue interacting with the system</p>

				
				0- End USSD session i.e display text as final and end the
                                 user session
</td>
										</tr>
										
									</tbody>
								</Table>
								<h2> Expected  App Response</h2>
								<pre><code>
								{'{'}{'\n'}
								"sessionId": "15794051211",{'\n'}
								"message": "Option1. Option2\n2. Option3\n3. Option4\n",{'\n'}
								"ContinueSession": "1"{'\n'}
								{'}'}

								</code>
								</pre>




			
							</Col>
						</Row>
					</Col>

					<Col lg={6} xs={6} className='rightbar'>
						<Image
							src={img}
							title='Introduction Documentation'
							alt='Introduction Documentation'
							className='img-fluid'
						/>
					</Col>
				</Row>
			</AppLayout>
		);
	}
}

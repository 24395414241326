export default {
	ruby: `require "uri"
require "net/http"

url = URI("https://api.mista.io/sms")

https = Net::HTTP.new(url.host, url.port);
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["x-api-key"] = "{{api_key}}"
form_data = [['action', 'send-sms'],['to', 'phone number'],['from', 'Sender ID'],['sms', 'YourMessage'],['schedule', 'date and time']]
request.set_form form_data, 'multipart/form-data'
response = https.request(request)
puts response.read_body`,

	python: `import requests

url = "https://api.mista.io/sms"

payload = {'action': 'send-sms',
'to': 'phone number',
'from': 'Sender ID',
'sms': 'YourMessage',
'schedule': 'date and time'}
files = [

]
headers = {
  'x-api-key': '{{api_key}}'
}

response = requests.request("POST", url, headers=headers, data = payload, files = files)

print(response.text.encode('utf8'))`,

	php: `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "https://api.mista.io/sms",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => array('action' => 'send-sms','to' => 'phone number','from' => 'Sender ID','sms' => 'YourMessage','schedule' => 'date and time'),
  CURLOPT_HTTPHEADER => array(
    "x-api-key: {{api_key}}"
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`,

	javascript: `var form = new FormData();
form.append("action", "send-sms");
form.append("to", "phone number");
form.append("from", "Sender ID");
form.append("sms", "YourMessage");
form.append("schedule", "date and time");

var settings = {
  "url": "https://api.mista.io/sms",
  "method": "POST",
  "timeout": 0,
  "headers": {
    "x-api-key": "{{api_key}}"
  },
  "processData": false,
  "mimeType": "multipart/form-data",
  "contentType": false,
  "data": form
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

	java: `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("text/plain");
RequestBody body = new MultipartBody.Builder().setType(MultipartBody.FORM)
  .addFormDataPart("action", "send-sms")
  .addFormDataPart("to", "phone number")
  .addFormDataPart("from", "Sender ID")
  .addFormDataPart("sms", "YourMessage")
  .addFormDataPart("schedule", "date and time")
  .build();
Request request = new Request.Builder()
  .url("https://api.mista.io/sms")
  .method("POST", body)
  .addHeader("x-api-key", "{{api_key}}")
  .build();
Response response = client.newCall(request).execute();`,

	curl: `curl --location --request POST 'https://api.mista.io/sms' \
--header 'x-api-key: {{api_key}}' \
--form 'action=send-sms' \
--form 'to=phone number' \
--form 'from=Sender ID' \
--form 'sms=YourMessage' \
--form 'schedule=date and time'`,

	http: `POST /sms HTTP/1.1
Host: api.mista.io
x-api-key: {{api_key}}Content-Type: multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW

----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="action"

send-sms
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="to"

phone number
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="from"

Sender ID
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="sms"

YourMessage
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="schedule"

date and time
----WebKitFormBoundary7MA4YWxkTrZu0gW`,

	response: `{
    "code": "ok",
    "message": "Successfully Sent",
    "messageId": "success|MxXid_6763546b9b0ddcd50ef487b8fe738759",
    "cost": 1,
    "time": 4.0887370109558105,
    "balance": 1000
}`,
};

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { WelcomePage } from './pages/welcome';

import { HomePage } from './pages/sms/home';
import { PlainSMSPage } from './pages/sms/plain-sms';
import { AccountBalancePage } from './pages/sms/account-balance';
import { InsertContactPage } from './pages/sms/insert-contact';
import { ScheduleSMSPage } from './pages/sms/schedule-sms';
import { UnicodeSMSPage } from './pages/sms/unicode-sms';
import { TextToSpeechPage } from './pages/sms/text-to-speech';
import { FethcMessagePage } from './pages/sms/fetch-messages';
import { RequestSenderIdPage } from './pages/sms/request-sender-id';
import { DeliveryReport } from './pages/sms/delivery-report';

import { HomeUSSDPage } from './pages/ussd/home';
import { HandleSessions } from './pages/ussd/handle-sessions';
import { SampleCodes } from './pages/ussd/sample-codes';

export default class App extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path='/' component={WelcomePage} />

				<Route exact path='/sms' component={HomePage} />
				<Route exact path='/sms/plain-sms' component={PlainSMSPage} />
				<Route
					exact
					path='/sms/account-balance'
					component={AccountBalancePage}
				/>
				<Route exact path='/sms/insert-contact' component={InsertContactPage} />
				<Route exact path='/sms/schedule-sms' component={ScheduleSMSPage} />
				<Route exact path='/sms/unicode-sms' component={UnicodeSMSPage} />
				<Route exact path='/sms/text-to-speech' component={TextToSpeechPage} />
				<Route exact path='/sms/fetch-messages' component={FethcMessagePage} />
				<Route
					exact
					path='/sms/request-sender-id'
					component={RequestSenderIdPage}
				/>
				<Route exact path='/sms/delivery-report' component={DeliveryReport} />

				<Route exact path='/ussd' component={HomeUSSDPage} />
				<Route exact path='/ussd/handle-sessions' component={HandleSessions} />
				<Route exact path='/ussd/sample-codes' component={SampleCodes} />
			</Switch>
		);
	}
}

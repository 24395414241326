import React from 'react';
import { Link } from 'react-router-dom';
import {
	Container,
	Row,
	Col,
	Navbar,
	Image,
	Form,
	Nav,
	Button,
} from 'react-bootstrap';

import MistaLogo from '../assets/img/mistalogo.svg';

export class WelcomePage extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Navbar
					collapseOnSelect
					sticky='top'
					variant='light'
					bg='white'
				>
					<Navbar.Brand>
						<Image
							alt='Mista SMS'
							title='Mista SMS'
							src={MistaLogo}
							width='90%'
							className='d-inline-block align-top ml-2'
						/>
					</Navbar.Brand>

					<Nav className='ml-auto'></Nav>

					<Form inline>
						<Button href='https://dashboard.mista.io' variant='outline-success'>
							SIGN IN
						</Button>
					</Form>
				</Navbar>

				<section className='api-background'>
					<Container className='py-5'>
						<Row className='justify-content-center text-center'>
							<Col lg={8}>
								<h2>API Docs</h2>
								<p className='mt-5'>
									{' '}
									We have many API options, so you can start adding messaging
									functionality to your app right away.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section className='api-options py-5'>
					<Container className='mt-5 mb-5'>
						<Row>
							<Col lg={4} className='mb-5'>
								<div className='api-option-box text-center p-5'>
									<h2>SMS API</h2>
									<p className='mt-3'>
										Send SMS programmatically with most powerful API with JSON request and response.{' '}
									</p>

									<Link
										to='/sms'
										className='btn btn-outline-primary pl-5 pr-5 mt-4'
									>
										View docs
									</Link>
								</div>
							</Col>

							<Col lg={4}>
								<div className='api-option-box text-center p-5'>
									<h2>USSD API</h2>
									<p className='mt-3'>
										Integrate and our USSD API and start sending and receiving USSD request and poweful Analytics on the dashboard {' '}
									</p>

									<Link
										to='/ussd'
										className='btn btn-outline-primary pl-5 pr-5 mt-4'
									>
										View docs
									</Link>
								</div>
							</Col>
							<Col lg={4}>
								<div className='api-option-box text-center p-5'>
									<h2>USE CASE</h2>
									<p className='mt-3'>
										Possible Usecases and what you can innovatively Build at the top of our Infrastructure.{' '}
									</p>

									<Link
										to='/usecase'
										className='btn btn-outline-primary pl-5 pr-5 mt-4'>
										View docs
									</Link>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<footer>
					<Container className='py-5'>
						<Row className='justify-content-center text-center pb-5'>
							<Col lg={8}>
								<h2 style={{ color: '#fff', fontSize: '28px' }}>
									Join our Developer Community
								</h2>
								<p className='mt-3' style={{ fontSize: '18px' }}>
									Discover reliable, real-time communication solutions that help
									businesses to connect with customers in a flexible and cost
									effective way.{' '}
								</p>

								<Button
									href='https://dashboard.mista.io/signup'
									variant='primary'
									className='py-3 pl-5 pr-5 mt-3'
									style={{
										borderTopLeftRadius: '25px',
										borderBottomRightRadius: '25px',
									}}
								>
									GET STARTED
								</Button>
							</Col>
						</Row>

						<p className='text-center mt-5'>
							© 2021 Mista. All rights reserved
						</p>
					</Container>
				</footer>
			</>
		);
	}
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap';

export class SmsSidebar extends React.Component {
	render() {
		const { sidebarHidden } = this.props;
		
		return (
			<Col lg={2} className={sidebarHidden ? 'sidebar' : 'sidebar-show'}>
				<div className='mt-5 mb-5'>
					<p class='ml-2'>SMS Api Requests</p>

					<div className='menu mt-2'>
						<li class='nav-item'>
							<Link
								to='/sms'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Overview
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/plain-sms'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Sending Plain SMS
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/account-balance'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Check Account Balance
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/insert-contact'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Insert Contact
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/schedule-sms'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Send Schedule SMS
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/unicode-sms'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Unicode SMS
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/text-to-speech'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Text to Speech
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/fetch-messages'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Fetch messages
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/request-sender-id'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Request Sender ID
							</Link>
						</li>

						<li class='nav-item'>
							<Link
								to='/sms/delivery-report'
								className='nav-link'
								onClick={this.hideSideBar}
							>
								Delivery Report
							</Link>
						</li>

						<Button href='https://dashboard.mista.io' variant='outline-success'>
							SIGN IN
						</Button>
					</div>
				</div>
			</Col>
		);
	}
}

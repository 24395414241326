export default {
	ruby: `require "uri"
require "net/http"

url = URI("https://api.mista.io/sms")

https = Net::HTTP.new(url.host, url.port);
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["x-api-key"] = "{{api_key}}"
form_data = [['action', 'senderIDrequest'],['sender_id', 'TEST2']]
request.set_form form_data, 'multipart/form-data'
response = https.request(request)
puts response.read_body`,

	python: `import requests

url = "https://api.mista.io/sms"

payload = {'action': 'senderIDrequest',
'sender_id': 'TEST2'}
files = [

]
headers = {
  'x-api-key': '{{api_key}}'
}

response = requests.request("POST", url, headers=headers, data = payload, files = files)

print(response.text.encode('utf8'))`,

	php: `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "https://api.mista.io/sms",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => array('action' => 'senderIDrequest','sender_id' => 'TEST2'),
  CURLOPT_HTTPHEADER => array(
    "x-api-key: {{api_key}}"
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`,

	javascript: `var form = new FormData();
form.append("action", "senderIDrequest");
form.append("sender_id", "TEST2");

var settings = {
  "url": "https://api.mista.io/sms",
  "method": "POST",
  "timeout": 0,
  "headers": {
    "x-api-key": "{{api_key}}"
  },
  "processData": false,
  "mimeType": "multipart/form-data",
  "contentType": false,
  "data": form
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

	java: `OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("text/plain");
RequestBody body = new MultipartBody.Builder().setType(MultipartBody.FORM)
  .addFormDataPart("action", "senderIDrequest")
  .addFormDataPart("sender_id", "TEST2")
  .build();
Request request = new Request.Builder()
  .url("https://api.mista.io/sms")
  .method("POST", body)
  .addHeader("x-api-key", "{{api_key}}")
  .build();
Response response = client.newCall(request).execute();`,

	curl: `curl --location --request POST 'https://api.mista.io/sms' \
--header 'x-api-key: {{api_key}}' \
--form 'action=senderIDrequest' \
--form 'sender_id=TEST2'`,

	http: `POST /sms HTTP/1.1
Host: api.mista.io
x-api-key: {{api_key}}Content-Type: multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW

----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="action"

senderIDrequest
----WebKitFormBoundary7MA4YWxkTrZu0gW
Content-Disposition: form-data; name="sender_id"

TEST2
----WebKitFormBoundary7MA4YWxkTrZu0gW`,

	response: `{
    "code": "ok",
    "message": "Sender  Successfully requested"
}
`,
};
